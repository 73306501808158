.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  font-family: "Inter", sans-serif;
  /* background-color: #282c34; */
  background: url(https://res.cloudinary.com/dd1vegw4m/image/upload/f_auto/v1716076277/comapreitt/y1btz5hgwxub803ocunv.jpg) no-repeat center bottom fixed;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */

  color: white;
  position: relative;
}

p {
  font-size: 1rem;
  margin: 0;
  color: #000;
}

.main {
  padding-top: 20vh;
  font-size: calc(10px + 2vmin);
  font-weight: 700;
  margin-bottom: 20px;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

copy {
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-size: 0.7rem;
  color: #ccc;
  margin-top: 20px;
  text-transform: uppercase;
  background-color: rgba(0, 0, 0, 0.2);
  padding: 3px 5px;
}

copy a {
  color: #ccc;
  text-decoration: none;
  font-weight: bold;
}